


function Header() {


  return (
    <header className="max-w-5xl m-auto flex items-center justify-between space-x-2 font-bold py-5 mb-10 px-4 lg:px-0 ">
      {/* <div className="flex items-center ">
                <Link href="/">
          <div className="hidden lg:block">
            <Image className="" src="/assets/logo.png" alt="logo" width={300} height={150} />
          </div>
        </Link> 
      </div>*/}


      {/* Social Icons */}
      <div className="flex m-auto">
        <a href="https://www.linkedin.com/in/enriqueiturriaga/"  target="_blank" rel="noreferrer" className="border-[1px] p-3 bg-[#131416]/50 rounded-sm	 border-[#2E2E2E] mr-3 drop-shadow-blue-glow hover:drop-shadow-blue-glow-hover transition-all">
          <img className="cursor-pointer min-h-[20px] min-w-[20px]" src="/assets/linkedin-icon.svg" alt="linkedin" width={30} height={30} />
        </a>
        <a href="https://twitter.com/iturriagaayala" target="_blank" rel="noreferrer" className="border-[1px] p-3 bg-[#131416]/50 rounded-sm	 border-[#2E2E2E] mr-3 drop-shadow-blue-glow hover:drop-shadow-blue-glow-hover transition-all">
          <img className="cursor-pointer min-h-[20px] min-w-[20px]" src="/assets/twitter-icon.svg" alt="linkedin" width={30} height={30} />
        </a>
        <a href="https://github.com/enriqueiturriagaa"  target="_blank" rel="noreferrer" className="border-[1px] p-3 bg-[#131416]/50 rounded-sm	 border-[#2E2E2E] mr-3 drop-shadow-blue-glow hover:drop-shadow-blue-glow-hover transition-all">
          <img className="cursor-pointer min-h-[20px] min-w-[20px]" src="/assets/github-icon.svg" alt="linkedin" width={30} height={30} />
        </a>

      </div>      
    </header>
  )
}

export default Header