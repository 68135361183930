import React from 'react'

function Developer() {
  return (
    <div className='w-80 m-auto md:mx-auto lg:mx-0 md:min-w-min p-10 bg-[#1F1F24] rounded-sm text-center my-10  '>
        <img alt='Logo' src="/assets/logo.png" className='max-w-[180px] max-h-[90px] m-auto'/>
        <div className="flex font-roboto mt-8 ">
            <a
                    href="https://dev.enrique.zone"
                    target="_blank"
                    className="m-auto px-8 py-3 border-2 border-[#6683F1] rounded-sm md:text-base bg-[#1F1F24] hover:bg-[#6683F1] text-[#6683F1] hover:text-[#1F1F24] transition-all flex items-center drop-shadow-gold-glow hover:drop-shadow-gold-glow-hover " rel="noreferrer"
                >
                    <p className="text-xs  font-semibold ">Development Portfolio</p>
            </a>
        </div>
    </div>
  )
}

export default Developer