
import React from 'react'
import Designer from './Designer'
import Developer from './Developer'


function Hero() {
    return (
        <div className=' max-w-5xl m-auto px-4 lg:px-0 text-center font-roboto my-20'>
            <h1 className='font-semibold  text-4xl  mt-10 mb-5'>Hey there! I’m Enrique a <span className='text-[#6683F1]'>Developer</span> and <span className='text-[#54B197]'>UX/UI Designer.</span></h1>
            <p className='text-xl font-normal text-[#F6931A]'>Which one of my portfolios do you want to see?</p>
            <div className=' lg:flex items-center justify-center lg:space-x-16 lg:m-auto text-center'>
                <Developer />
                <Designer />
            </div>
            
        </div>
    )
}

export default Hero