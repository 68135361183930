import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Spinner from "./components/Spinner";

export default function App() {
  return (
    <div className='bg-[#191A22] bg-hero-pattern text-white min-h-screen mx-auto font-roboto'>
      <Header />
      <Hero />
      <Spinner />
      <Footer />
    </div>
  )
}